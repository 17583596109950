import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/link/accessibility",
  "title": "Link - Accessibility"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The HDS link component is primarily a native HTML hyperlink navigational element. The Enter key activates the link and causes the user agent to move focus to the link destination.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Remember to tell users if the link is going to open an entirely new site. In HDS React Link this feature is provided through external links (prop `}<inlineCode parentName="p">{`external`}</inlineCode>{`). There is a default aria-label for screen readers. This can be overwritten by using the `}<inlineCode parentName="p">{`openInExternalDomainAriaLabel`}</inlineCode>{` prop.
In HDS Core, set a proper `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to the anchor tag as shown in the examples below.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remember to tell users if the link is going to open in a new tab. In HDS React Link this feature is provided through prop `}<inlineCode parentName="p">{`openInNewTab`}</inlineCode>{`. There is a default aria-label for screen readers. This can be overwritten by using the `}<inlineCode parentName="p">{`openInNewTabAriaLabel`}</inlineCode>{` prop.
In HDS Core, set a proper `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to the anchor tag as shown in the examples below.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Some assistive technologies provide a list of all links on the page to its user. If the text of the links does not clearly give the purpose, or if multiple links with the same name point to different targets (e.g., “read more”), users are forced to locate the link on the page and search surrounding information for context.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you want to use a generic link text such as "Read more" but want to provide more detailed information for screen readers, you can use the `}<inlineCode parentName="p">{`ariaLabel`}</inlineCode>{` property. However please note that as per the `}<ExternalLink href="https://www.w3.org/TR/accname/" mdxType="ExternalLink">{`Accessible Name and Description Computation`}</ExternalLink>{` and the `}<ExternalLink href="https://www.w3.org/TR/html-aapi/#accessible-name-and-description-calculation" mdxType="ExternalLink">{`HTML to Platform Accessibility APIs Implementation Guide`}</ExternalLink>{`, the aria-label text will override the text supplied within the link. As such the text supplied will be used instead of the link text by AT. Due to this it is recommended to start the text used in aria-label with the text used within the link. This will allow consistent communication between users.`}</p>
      </li>
    </ul>
    <p>{`Make sure that:`}</p>
    <ul>
      <li parentName="ul">{`Link text is the visible label for the native HTML link and is used to provide the purpose of the link which is clear and easy to understand for all users.`}</li>
      <li parentName="ul">{`The alt attribute for the img element is used to describe the purpose of a graphical link with an image inside the link.`}</li>
      <li parentName="ul">{`The title attribute can be used to supplement the link text with any additional useful description.`}</li>
      <li parentName="ul">{`The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context.`}</li>
      <li parentName="ul">{`Icons should always be the same colour as the link text`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      